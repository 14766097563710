import { checkIfPreprod, waitManifest } from '../helpers'
import { LOGISTICS_MANIFEST_LS } from './constants'

const { hostname } = window.location

export const getEnv = () => {
  if (hostname.includes('auto1-test.com')) {
    return 'auto1-apps.qa'
  }
  return checkIfPreprod() ? 'auto1-apps-pre.prod' : 'auto1-apps.prod'
}

export const checkIfValidManifest = (text: string) => {
  try {
    JSON.parse(text)
    return true
  } catch (e) {
    return false
  }
}
export const updateManifest = async () => {
  const receivedManifest = await waitManifest('logisticsManifestString')
  localStorage.setItem(LOGISTICS_MANIFEST_LS, receivedManifest)

  return receivedManifest
}
