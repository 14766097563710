import { initMp } from './mp'
import { initLogistics } from './logistics'
import { APP_VERSION } from 'common/constants'

export { MP_MANIFEST_LS } from './mp/constants'
export { LOGISTICS_MANIFEST_LS } from './logistics/constants'

export const setupMFEApps = () => {
  const cachedVersion = localStorage.getItem(APP_VERSION) || ''

  return Promise.all([initMp(cachedVersion), initLogistics(cachedVersion)])
}
