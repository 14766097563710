import { LOGISTICS_MANIFEST_LS, PACKAGES } from './constants'
import { checkIfValidManifest, getEnv, updateManifest } from './helpers'

export const initLogistics = async (cachedLegacyVersion: string) => {
  const cachedManifest = localStorage.getItem(LOGISTICS_MANIFEST_LS) || ''
  const versions: Record<string, string> = {}

  const finalUrl = `https://${getEnv()}.logistics.auto1.cloud`
  let usedManifest = cachedManifest || ''

  if (
    cachedLegacyVersion &&
    // eslint-disable-next-line no-underscore-dangle
    cachedLegacyVersion === window.__VERSION__ &&
    checkIfValidManifest(cachedManifest)
  ) {
    // don't wait
    updateManifest()
  } else {
    usedManifest = await updateManifest()
  }

  const data = JSON.parse(usedManifest)
  Object.assign(versions, data.aws.versions)

  for (const [packageName, globalVarName, fallbackVersion] of PACKAGES) {
    const version = versions[packageName] || fallbackVersion
    window[globalVarName] = `${finalUrl}/${packageName}/${version}/dist`
  }

  return
}
