export const ENV_DEV = 'DEV'
export const ENV_PROD = 'PROD'
export const ENV_QA = 'QA'

export const APP_VERSION = 'APP_VERSION'

export const API_VERSION_V2 = 'v2'

export const IS_MOBILE = 'isMobilesUp'
export const IS_TABLET = 'isTabletsUp'
export const IS_DESKTOP = 'isDesktopsUp'
export const IS_LARGE_DESKTOP = 'isLargerDesktopsUp'

export const NO_SUBCHANNEL: number = 0
export const CHANNEL_END: number = 0
export const CHANNEL_DIRECT_CUSTOMER_BID: number = 2
export const CHANNEL_24H_AUCTIONS: number = 3
export const CHANNEL_INSTANT_PURCHASE: number = 4
export const CHANNEL_CURATED_AUCTIONS_BID: number = 5
export const CHANNEL_CUSTOM_AUCTION: number = 6
export const CHANNEL_3RD_PARTY_CATALOGUE = 7
export const CHANNEL_DIRECT_BUY = 8
export const CHANNEL_ZRTDIRECT = 9
export const CHANNEL_REMA_PREVIEW = 10
export const CHANNEL_UNKNOWN: number = -1
export const SUBCHANNEL_DCBP: number = 1
export const SUBCHANNEL_LAST_RUN: number = 2
export const SUBCHANNEL_ZRT = 'zrt'

export const DELAY_CHANNEL_HELP_POPUP: number = 1500

// new auction model constants
export const NAM_CHANNEL_24H_AUCTIONS_D1 = '24D1'
export const NAM_CHANNEL_24H_AUCTIONS_D2 = '24D2'
export const NAM_CHANNEL_24H_AUCTIONS_D3 = '24D3'
export const NAM_CHANNEL_ZRT_AUCTIONS_D1 = 'ZRT24D1'
export const NAM_CHANNEL_ZRT_AUCTIONS_D2 = 'ZRT24D2'
export const NAM_CHANNEL_ZRT_AUCTIONS_D3 = 'ZRT24D3'
export const NAM_CHANNEL_LAST_RUN = 'LASTRUN'
export const NAM_CHANNEL_DCB = 'DCB'
export const NAM_CHANNEL_DCBP = 'DCBP'
export const NAM_CHANNEL_INSTANT_PURCHASE = 'INSTANTPURCHASE'
export const NAM_CHANNEL_CUSTOM_AUCTIONS = 'CUSTOM'
export const NAM_CHANNEL_3RD_PARTY_CATALOGUE = '3RD_PARTY_CATALOGUE'
export const NAM_CHANNEL_3RD_PARTY_CATALOGUE_TURBO = '3RD_PARTY_TURBO'
export const NAM_CHANNEL_DIRECT_BUY = 'DIRECT_BUY'
export const NAM_CHANNEL_ZRTDIRECT = 'ZRTDIRECT'
export const NAN_CHANNEL_BATCH_AUCTION_PREVIEW = 'REMA_PREVIEW'
export const NAN_CHANNEL_BATCH_AUCTION_PREBIDDING = 'REMA_PREBIDDING'
export const NAN_CHANNEL_BATCH_AUCTION_BIDDING = 'REMA_BIDDING'
export const NAM_CHANNEL_BATCH_A1_PREVIEW = 'A1_PREVIEW'
export const NAM_CHANNEL_BATCH_A1_PREBIDDING = 'A1_PREBID'
export const NAM_CHANNEL_BATCH_A1_BIDDING = 'A1_BIDDING'
export const NAM_CHANNEL_INVALID = 'INVALID' // internal state

// new channel filters
export const FILTER_CHANNEL_24H = '24h'
export const FILTER_CHANNEL_DCB = 'dcb'
export const FILTER_CHANNEL_IP = 'ip'
export const FILTER_CHANNEL_CA = 'ca'
export const FILTER_CHANNEL_CATALOGUE_AUCTIONS = 'cat'
export const FILTER_CHANNEL_DIRECT_BUY = 'dib'
export const FILTER_CHANNEL_ZRT_DIRECT = 'zd'
export const FILTER_CHANNEL_PERSONALIZED = 'personalized'
export const FILTER_CHANNEL_BATCH_AUCTIONS = 'batch'
// sources filters
export const AUTO1_INVENTORY = 'auto1'
export const REMARKETING_FILTER = 'remarketing'

// please keep in sync with the above variables (or find a better way to avoid the repetition)
export enum EnamChannelAuctionModels {
  NAM_CHANNEL_24H_AUCTIONS_D1 = '24D1',
  NAM_CHANNEL_24H_AUCTIONS_D2 = '24D2',
  NAM_CHANNEL_24H_AUCTIONS_D3 = '24D3',
  NAM_CHANNEL_ZRT_AUCTIONS_D1 = 'ZRT24D1',
  NAM_CHANNEL_ZRT_AUCTIONS_D2 = 'ZRT24D2',
  NAM_CHANNEL_ZRT_AUCTIONS_D3 = 'ZRT24D3',
  NAM_CHANNEL_LAST_RUN = 'LASTRUN',
  NAM_CHANNEL_DCB = 'DCB',
  NAM_CHANNEL_DCBP = 'DCBP',
  NAM_CHANNEL_INSTANT_PURCHASE = 'INSTANTPURCHASE',
  NAM_CHANNEL_CUSTOM_AUCTIONS = 'CUSTOM'
}

//power units
export enum PowerUnits {
  KW = 'kw',
  HP = 'hp'
}

// instant offer statuses
export const NO_OFFER = 'NO_OFFER'
export const OFFER_MADE_NO_EMAIL = 'OFFER_MADE_NO_EMAIL'
export const OFFER_MADE_SENT_EMAIL = 'OFFER_MADE_SENT_EMAIL'
export const REJECTED = 'REJECTED'

export const ASC_SORTING = 'asc'
export const DESC_SORTING = 'desc'
export const BID_LOW = 'status.low'
export const BID_TOP = 'status.top'
export const BID_NO_BID = 'status.no-offer'
export const BID_OUTBID = 'status.outbid'
export const BID_PENDING = 'status.pending'
export const BID_CRAZY = 'status.crazy'
export const EURO_SIGN_POSITION = 'left'
export const SERVICE_IMAGE_DEFAULT_TITLE = ''
export const AJAX_REQUEST_HEADERS: object = {
  'X-Requested-With': 'XMLHttpRequest'
}
export const BASIC_BACKEND_FORM_VALIDATION_MSG_KEY = 'body.errors.__form__.0'
export const FORM_POST_REQUEST_TYPE = 'form'

export const FILTER_NO_LIMIT = 'no'

// HTTP
export const HTTP_UNAUTHORIZED: number = 401
export const HTTP_OK: number = 200

export const HEADER_ACCESS_TOKEN_FIELD = 'Authorization'

export const TInstantOfferStatus = {
  NO_OFFER: 'NO_OFFER',
  OFFER_MADE_NO_EMAIL: 'OFFER_MADE_NO_EMAIL',
  OFFER_MADE_SENT_EMAIL: 'OFFER_MADE_SENT_EMAIL',
  REJECTED: 'REJECTED'
}
export type TInstantOfferStatus =
  typeof TInstantOfferStatus[keyof typeof TInstantOfferStatus]

export const INSPECTION_STATUS_NOT_INSPECTED_STRING = 'NOT_INSPECTED'
export const INSPECTION_STATUS_INSPECTED_STRING = 'INSPECTED'
export const INSPECTION_STATUS_UPLOADED_STRING = 'UPLOADED'
export const ON_ENTER_PAGE = 'on_enter_page'
export const ON_UPDATE_PAGE = 'on_update_page'
export const ON_LEAVE_PAGE = 'on_leave_page'

export const NO_ACCESS_VALUE: number = -100

export const DEFAULT_NO_VALUE = ''
export const EMPTY_PAGINATION = {
  currentPage: 0,
  offset: 0,
  perPage: 0,
  totalItems: 0,
  totalPages: 0
}

export const BUY_NOW_OFFER_TYPE = 'buy-now'

export const MINIMUM_BID_HIDDEN_VALUE = 1000
export const MPPRICE_HIDDEN_VALUE = 1000
export const SAVING_HIDDEN_VALUE = 1000
export const LAST_TOP_BID_HIDDEN_VALUE = 1000

export const REQUEST_CACHE_EXPIRATION_TIME = 0

export const QUERY_BACK_URL_PARAM_NAME = 'b'

export const SCROLL_DURATION: number = 500

export const PER_PAGE = 40
export const SOURCE_SEARCH = 'search'

export const CHUNK_LOAD_TIMEOUT = 10000 // 10s

// cache (in ms)
export const SUGGESTED_CARS_CACHE_TIMEOUT: number = 3600000 // 1h
export const SEARCH_RESULTS_CACHE_TIMEOUT: number = 300000 // 5min

export const IOS_MSI_APP_LINK =
  'https://itunes.apple.com/de/app/auto1-trade-in/id1157875945?mt=8'
export const ANDROID_MSI_APP_LINK =
  'https://play.google.com/store/apps/details?id=com.auto1.msi'
export const DISPLAY_DELAY = 1000

export const CAR_IMAGES_ASPECT_RATIO: number = 800 / 600

export const ENGINE_VIDEO = 'overview_engine_video'

export const OBD_NO_ERRORS_CODE = 'P0000'

//saved searches subscriptions
export const SUBSCRIBED = 'subscribed'
export const SUBSCRIBED_DCB = 'subscribedDcb'
export const SUBSCRIBED_24H = 'subscribed24h'
export const SUBSCRIBED_DIRECT_BUY = 'subscribedDirectBuy'
export const SUBSCRIBED_ZRT_DIRECT = 'subscribedZrtdirect'

// Freshdesk config
type TFreshDeskConfig = {
  baseUrl
  apiKey
}

export const ROLE_LIVE_MARKET = 'ROLE_LIVE_MARKET'
export const ROLE_DCB_EXP_PREFIX = 'ROLE_DCB_EXP_'
export const ROLE_DCB_EXP_0 = 'ROLE_DCB_EXP_0'
export const ROLE_DCB_EXP_99 = 'ROLE_DCB_EXP_99'
export const ROLE_DCB_EXP_299 = 'ROLE_DCB_EXP_299'
export const ROLE_DCB_EXP_499 = 'ROLE_DCB_EXP_499'
export const ROLE_DCB_EXP_699 = 'ROLE_DCB_EXP_699'
export const ROLE_DCB_EXP_899 = 'ROLE_DCB_EXP_899'

export const ROLE_MERCHANT_COMMUNICATION = 'ROLE_MERCHANT_COMMUNICATION'
export const CAR_VIEW_BASE_URL = 'https://admin.wkda.de/car/view/'

export const ROLE_RECOMMENDER_USER = 'ROLE_RECOMMENDER_USER'
export const ROLE_RECOMMENDER_ML = 'ROLE_NEW_RECOMENDER_ML'
export const ROLE_RECOMMENDER_GROUP = 'ROLE_RECOMMENDER_GROUP'

export const ROLE_MERCHANT_REQUEST_CALLBACK = 'ROLE_MERCHANT_REQUEST_CALLBACK'

export const ROLE_RETURN_RIGHT = 'ROLE_RETURN_RIGHT'

export const ROLE_MERCHANT_ZOOM_WEBINAR_BANNER_ACTIVE =
  'ROLE_MERCHANT_ZOOM_WEBINAR_BANNER_ACTIVE'
export const ROLE_MERCHANT_ZOOM_WEBINAR_BANNER_ACTIVATION =
  'ROLE_MERCHANT_ZOOM_WEBINAR_BANNER_ACTIVATION'

export const ROLE_MERCHANT_ZOOM_WEBINAR_BANNER_REACTIVATION =
  'ROLE_MERCHANT_ZOOM_WEBINAR_BANNER_REACTIVATION'

export const ROLE_SINGLE_SAVED_SEARCH_PAGE = 'ROLE_SINGLE_SAVED_SEARCH_PAGE'

export const ENABLE_AUCTION_TAB_FILTER_ROLE = 'ENABLE_AUCTION_TAB_FILTER_ROLE'

export const ROLE_NOTIFICATION_SETTINGS = 'ROLE_NOTIFICATION_SETTINGS'

export const MERCHANT_VIEW_BASE_URL = 'https://admin.wkda.de/merchant/view/'

export const FRESH_DESK_CONFIG: TFreshDeskConfig = {
  baseUrl: 'https://auto1.freshdesk.com/api/v2/',
  apiKey: 'NmN2NDA3RElOeXN5eGdUVkxhRlQ='
}

// Freshdesk groups (DE/ES)

export const FRESH_DESK_GROUPS_ID = {
  DE: 2015000010112,
  ES: 2015000015876
}

export const BODY_TYPES = 'bodyTypes'
export const COMPOSITE_CAR_KEYS = 'compositeCarKeys'
export const COLORS = 'colors'
export const DOORS = 'doors'
export const EXPORT_ADVANTAGE = 'exportAdvantage'
export const ACCIDENT = 'accident'
export const FUEL_TYPES = 'fuelTypes'
export const GUARANTEED_SALES = 'guaranteedSales'
export const IS_CASHBACK_ELIGIBLE = 'isCashBackEligible'
export const LAST_RUN = 'lastRun'
export const LOCAL_REVERSE_CHARGE = 'localReverseCharge'
export const PAIN_PRICE_AGREEMENT = 'painPriceAgreement'
export const PREMIUM_HANDLING = 'premiumHandling'
export const TAX_DEDUCTION = 'taxDeduction'
export const EQUIPMENTS = 'searchEquipments'
export const LOCATION = 'location'
export const FIRST_REGISTRATION = 'firstRegistration'
export const MILEAGE = 'mileage'
export const POWER = 'power'
export const CUSTOM_AUCTIONS = 'customChannelUrls'
export const VALUE_TO_IGNORE = 'all'
export const SOURCES = 'sources'
export const ZRT_SOURCES = 'zrtSources'
export const CAR_CONDITION = 'carCondition'
export const EMISSION_STANDARDS = 'emissionStandards'
export const GEAR_TYPES = 'gearTypes'
export const SEATS = 'seats'
export const TOTAL_OWNERS = 'totalOwners'
export const SERVICE_APPOINTMENTS = 'serviceAppointments'
export const COUNTRY_OF_ORIGIN = 'countryOfOrigin'
export const NUMBER_OF_KEYS = 'numberOfKeys'
export const TEST_DRIVE_FINDINGS = 'testDriveFindings'
export const ACCIDENTS = 'accidents'
export const AUCTION_BATCHES = 'auctionBatchIds'

export const DOORS_OTHER: number = -1
export const SEATS_OTHER: number = -1
export const TOTAL_OWNERS_OTHER: number = -1
export const NUMBER_OF_KEYS_OTHER: number = -1

export const SEARCH_NAME = 'name'
export const SEARCH_ID = 'searchId'
export const SEARCH_SUBSCRIBED = 'subscribed'

// new values used after switch to java endpoints
export const GUARANTEED_SALES_JAVA = 'guaranteedSalesEligible'
export const EXPORT_ADVANTAGE_JAVA = 'exportAdvantageEligible'
export const PREMIUM_HANDLING_JAVA = 'premiumHandlingEligible'
export const PREMIUM_RETURN_RIGHT = 'prrEligible'
export const A1_FINANCE = 'a1FinanceEligible'
export const IS_CASHBACK_ELIGIBLE_JAVA = 'cashbackEligible'
export const IS_NO_BIDDING_ELIGIBLE_JAVA = 'noBiddingEligible'
export const PACKAGE_DEAL_ELIGIBLE = 'packageDealEligible'
export const COLOURS = 'colours'
export const EQUIPMENTS_JAVA = 'equipments'
export const MILEAGE_RANGE = 'mileageRange'
export const POWER_RANGE = 'powerRange'
export const FIRST_REGISTRATION_RANGE = 'firstRegistrationRange'
export const PRICE_RANGE = 'priceRange'
export const BRANCH_COUNTRIES = 'branchCountries'
export const CAR_FILTERS = 'carFilters'
export const PROMOTIONS = 'promotions'
export const BRANDS = 'brands'

export const EQUIPMENT_LABEL_MAP = {
  allWheelDrive: 'a1-search-equipment-allWheelDrive',
  pdc: 'a1-search-equipment-pdc',
  heatedSeats: 'a1-search-equipment-heatedSeats',
  parkHeating: 'a1-search-equipment-parkHeating',
  ac: 'a1-search-equipment-ac',
  navigationSystem: 'a1-search-equipment-navigationSystem',
  xenon: 'a1-search-equipment-xenonLights',
  sunroof: 'a1-search-equipment-sunroof',
  leather: 'a1-search-equipment-leather',
  automatic: 'a1-search-equipment-automatic'
}

//Search Page keys
export const SEARCH_PAGE = 'searchPage'
export const SEARCH_SETTINGS = 'settings'
export const SEARCH_INFO = 'searchInfo'

// minimal difference between counteroffer and top bid
export const MIN_BID_COUNTEROFFER_DIFFERENCE = 10000

// role to allow access to remarketing request landing page
export const ROLE_REQUEST_ACCESS_REMARKETING = 'ROLE_REQUEST_ACCESS_REMARKETING'

// remarketing self signup banner
export const BANNER_POSITION_MIN_NUMBER = 1
export const BANNER_POSITION_MAX_NUMBER = 5
export const BANNER_PAGE_MIN_NUMBER = 1
export const BANNER_PAGE_MAX_NUMBER = 5
export const SEK_CONVERSION_RATE_FALLBACK = 10.4

// my orders page unpaid invoice types
export const UNPAID_INVOICE_PROFORMA_TYPE = 100

export const HIDE_COOKIE_BANNER_FLAG = 'hideCookieBanner'
