import { PREPROD_HOST } from './constants'

const delay = (t: number) => new Promise(r => setTimeout(r, t))

const getTimeOfDelay = (numOfTry: number) => {
  if (numOfTry < 20) {
    return 30 // 600
  } else if (numOfTry < 60) {
    return 50 // 2600
  } else if (numOfTry < 100) {
    return 300 // 14600
  }

  return 1000
}

export const waitManifest = async (field: string) => {
  let numOfTry = 0

  while (!globalThis[field]) {
    await delay(getTimeOfDelay(numOfTry))
    numOfTry++
  }

  return globalThis[field] as string
}

export const checkIfPreprod = () => window.location.hostname === PREPROD_HOST
