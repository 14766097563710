import { MpManifest, MpManifestSaved, MpVersions } from './types'
import { waitManifest } from '../helpers'
import { MP_MANIFEST_LS } from './constants'

export const checkIfMpManifestValid = (text: string) => {
  try {
    const parsedManifest = JSON.parse(text) as MpManifest
    const { version, ...mfes } = parsedManifest
    return Object.values(mfes).every(mfe =>
      Object.values(mfe).every(mfeLink => {
        const mfeUrl = new URL(mfeLink)
        return /^https:\/\/.+\.auto1.cloud$/.test(mfeUrl.origin)
      })
    )
  } catch (e) {
    return false
  }
}

const findSuitableVersionMp = (currentVersion: string, mfes: MpVersions) => {
  const versions = Object.keys(mfes || {})
  if (!versions.length) {
    return undefined
  }

  if (!currentVersion || currentVersion === 'latest') {
    return 'latest'
  }

  const numericVersions = versions
    .filter(version => version !== 'latest')
    .map(version => version.split('.').map(Number))

  const currentVersionParts = currentVersion.split('.').map(Number)

  numericVersions.sort((a, b) => {
    for (let i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) {
        return a[i] - b[i]
      }
    }
    return 0
  })

  const suitableVersion = numericVersions.find(version => {
    for (let i = 0; i < currentVersionParts.length; i++) {
      if (version[i] > currentVersionParts[i]) {
        return true
      } else if (version[i] < currentVersionParts[i]) {
        return false
      }
    }
    return true
  })

  return suitableVersion ? suitableVersion.join('.') : 'latest'
}

export const prepareMpManifest = (text: string) => {
  const data = JSON.parse(text) as MpManifest

  return Object.keys(data).reduce((acc, mfe) => {
    // eslint-disable-next-line no-underscore-dangle
    const suitableVersion = findSuitableVersionMp(window.__VERSION__, data[mfe])

    if (suitableVersion) {
      acc[mfe] = data[mfe][suitableVersion]
    }

    return acc
  }, {} as MpManifestSaved)
}

export const updateManifest = async () => {
  const receivedManifest = await waitManifest('mpManifestString')
  localStorage.setItem(MP_MANIFEST_LS, receivedManifest)

  return receivedManifest
}
