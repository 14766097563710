import { MP_MANIFEST_LS } from './constants'
import {
  checkIfMpManifestValid,
  prepareMpManifest,
  updateManifest
} from './helpers'

export const initMp = async (cachedLegacyVersion: string) => {
  const cachedManifest = localStorage.getItem(MP_MANIFEST_LS) || ''
  let usedManifest = cachedManifest

  if (
    cachedLegacyVersion &&
    // eslint-disable-next-line no-underscore-dangle
    cachedLegacyVersion === window.__VERSION__ &&
    checkIfMpManifestValid(cachedManifest)
  ) {
    // don't wait
    updateManifest()
  } else {
    usedManifest = await updateManifest()
  }

  window.mpManifest = prepareMpManifest(usedManifest)

  return window.mpManifest
}
